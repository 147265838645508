import type { PermissionState } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import {
  BehaviorSubject,
  catchError,
  combineLatestWith,
  exhaustMap,
  from,
  map,
  merge,
  of,
  shareReplay,
  takeWhile,
  timer,
} from "rxjs";

import { CityListQuery } from "../graphql/graphql";
import { geolocationService } from "./services/geolocation";

type GeolocationCheckingPermission = PermissionState | "checking";

export const geolocationPermissionStatus$ =
  new BehaviorSubject<GeolocationCheckingPermission>("checking");

const refreshGeolocation$ = new BehaviorSubject<void>(undefined);

export const watchGeolocationPermissionQuery$ = timer(0, 1000).pipe(
  exhaustMap(() => from(Geolocation.checkPermissions())),
  map((permission) => {
    switch (permission.location) {
      case "granted":
        refreshGeolocation$.next();
        geolocationPermissionStatus$.next("granted");
        break;
      case "denied":
      case "prompt":
      case "prompt-with-rationale":
        geolocationPermissionStatus$.next("denied");
        break;
    }
    return permission.location;
  }),
  takeWhile((permission) => permission !== "granted", true),
  shareReplay(1),
);

const locationSubject$ = new BehaviorSubject<
  null | CityListQuery["cityList"][0]
>(null);

export const useDiscoveryLocationAsUserLocation = ({
  lat,
  lng,
}: {
  lat: number;
  lng: number;
}) => {
  // @ts-ignore
  refreshGeolocation$.next({ lat, lng });
};

export const getCurrentUserLocation = () => {
  refreshGeolocation$.next();
};

export const useCurrentCityAsUserLocation = (
  city: CityListQuery["cityList"][0],
) => {
  refreshGeolocation$.next(city);
};

const geolocation$ = refreshGeolocation$.pipe(
  exhaustMap(() =>
    geolocationService.getCurrentPosition({ enableHighAccuracy: true }),
  ),
  catchError(() => of(null)),
  shareReplay(1),
);

export const location$ = merge(
  geolocationService.getCurrentPosition({ enableHighAccuracy: true }),
  geolocation$,
).pipe(
  combineLatestWith(
    geolocationPermissionStatus$.pipe(catchError(() => of("denied"))),
    refreshGeolocation$,
  ),
  map(([userLocation, permission]) => {
    if (userLocation) {
      return userLocation.coords;
    }

    if (!userLocation && permission === "denied") {
      return null;
    }
  }),
  catchError(() => of(null)),
  shareReplay(),
);
