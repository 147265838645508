import { SocialLogin } from '@capgo/capacitor-social-login';
import * as React from "react";

export const initialize = async () => {
  const clientId = "19293718339-9qa1ko6paik4b93cmug71vh4hpqsp66n.apps.googleusercontent.com";

  try {
    if (isInitialized) return;

    await SocialLogin.initialize({
      google: {
        webClientId: clientId,
        iOSClientId: clientId,
        iOSServerClientId: clientId,
        mode: "online",
      }
    });
    isInitialized = true;
  } finally {
    emitChange();
  }
};

let isInitialized = false;
let listeners = [];

export const googleAuthStore = {
  subscribe(listener) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  },
  getSnapshot() {
    return isInitialized;
  },
};

function emitChange() {
  for (let listener of listeners) {
    listener();
  }
}

export const useIsGoogleAuthInitialized = () => {
  React.useEffect(() => {
    initialize();
  }, []);

  return React.useSyncExternalStore(
    googleAuthStore.subscribe,
    googleAuthStore.getSnapshot,
  );
};
